import React, { useRef } from "react";
import { useButton } from "@react-aria/button";
import { AriaButtonProps } from "@react-types/button";
import { FaPlayCircle, FaPauseCircle } from "react-icons/fa";
import { useFocusRing } from "@react-aria/focus";

export default function PlayPauseButton({
  isPlaying,
  label,
  size = 50,
  ...props
}: { label?: string; isPlaying: boolean; size: number } & AriaButtonProps) {
  const ref = useRef(null);
  const { buttonProps, isPressed } = useButton(
    { "aria-label": isPlaying ? "Pause" : "Play", ...props },
    ref
  );
  const { isFocusVisible, focusProps } = useFocusRing();

  const Icon = isPlaying ? FaPauseCircle : FaPlayCircle;

  return (
    // <FocusRing focusClass="focus-ring">
    <button
      className="play-button"
      style={{
        appearance: "none",
        border: "0 none",
        cursor: "pointer",
        background: "none",
        userSelect: "none",
        verticalAlign: "middle",
        outline: isFocusVisible ? undefined : "0 none",
      }}
      {...buttonProps}
      {...focusProps}
      ref={ref}
    >
      <Icon
        size={size}
        style={{
          verticalAlign: "bottom",
        }}
        color={isPressed ? "#29ABE2" : "#e6e6e6"}
      />{" "}
      {Boolean(label) && (
        <span
          style={{
            paddingLeft: "0.5em",
            paddingRight: "1em",
            color: "white",
            lineHeight: 2,
            fontSize: 30,
            fontStyle: "bold",
            textTransform: "uppercase",
            letterSpacing: 1.5,
          }}
        >
          {label}
        </span>
      )}
    </button>
    // </FocusRing>
  );
}
