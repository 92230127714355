import React from "react";
import WebFooter from "./WebFooter";
import MainMenu from "./MainMenu";
import NowPlaying from "./NowPlaying";

interface IWebAudioWrapProps {
  element?: React.ReactChildren;
}

function WebAudioWrap({ element }: IWebAudioWrapProps) {
  return (
    <>
      {element}
      <NowPlaying />
    </>
  );
}

export default WebAudioWrap;
